import type {
  EndpointClientFigmaOAuthCallback,
  EndpointClientFigmaOAuthCheck,
} from '@knapsack/api-server';
import { getUserToken } from '@/utils/user-token';
import { useQuery } from '@tanstack/react-query';
import { knapsackGlobal } from '@/global';
import { ksHttpHeaders } from '@knapsack/core';
import { extractPossibleInstanceIdFromSite } from '@/core/env-and-content-src/utils';
import { appApiUrl } from './app-api-client';

async function getHeaders() {
  const headers: Record<string, string> = {
    'Content-Type': 'application/json',
  };
  const { site } = knapsackGlobal.appService.getSnapshot().context;
  headers[ksHttpHeaders.siteId] = site?.meta.siteId;
  headers[ksHttpHeaders.instanceId] = extractPossibleInstanceIdFromSite(site);

  try {
    const token: string = await getUserToken();
    if (token) {
      headers.Authorization = `Bearer ${token}`;
    }
  } catch (e) {
    // Muffle error here. Error is logged within getUserToken()
  }
  return headers;
}

export const exchangeFigmaOAuthCodeForToken: EndpointClientFigmaOAuthCallback =
  async (body) => {
    const apiUrl = new URL('/design-srcs/figma-oauth', appApiUrl).toString();
    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: await getHeaders(),
      body: JSON.stringify(body),
    });
    return response.json();
  };

export const checkIfFigmaTokenExists: EndpointClientFigmaOAuthCheck =
  async () => {
    const apiUrl = new URL('/design-srcs/figma-oauth', appApiUrl).toString();
    const response = await fetch(apiUrl, {
      method: 'GET',
      headers: await getHeaders(),
    });

    if (response.status === 403) {
      throw new Error('Figma token is invalid or expired');
    }

    return response.json();
  };

export function useCheckIfFigmaTokenExists() {
  const queryInfo = useQuery({
    queryKey: ['checkIfFigmaTokenExists'],
    queryFn: () => checkIfFigmaTokenExists({}),
    retry: (failureCount, error) => {
      // Do not retry if error is 403
      if (error.message.includes('Figma token is invalid or expired')) {
        return false;
      }
      return failureCount < 3;
    },
  });

  return {
    ...queryInfo,
    data: queryInfo.data,
  };
}
